import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICoin } from '../interfaces/icoin';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public coins: ICoin[] = [];
  public list: ICoin[] = [];
  public currency = 'eur';

  constructor() {
    this.loadCoins();
    this.loadList();
    this.loadCurrency();
  }

  getCoins(): ICoin[] {
    return this.coins;
  }

  loadCoins(): void {
    this.coins = JSON.parse(localStorage.getItem('coins')) || [];
  }
  loadList(): void {
    this.list = JSON.parse(localStorage.getItem('list')) || [];
  }
  loadCurrency(): void {
    this.currency = JSON.parse(localStorage.getItem('currency')) || 'eur';
  }

  addCoin(coin: any): void {
    // merge object or add new
    if (this.coins.find((c: ICoin) => c.id === coin.id)) {
      this.coins = this.coins.map((c) => {
        if (c.id === coin.id) {
          return coin;
        }
        return c;
      });
    } else {
      this.coins.push(coin);
    }
    this.set('coins', JSON.stringify(this.coins));
  }

  saveCoins(): void {
    this.set('coins', JSON.stringify(this.coins));
  }

  deleteCoin(coin: ICoin): void {
    this.coins = this.coins.filter((c) => c.id !== coin.id);
    this.set('coins', JSON.stringify(this.coins));
  }

  getCurrency(): any {
    return this.currency || this.loadCurrency();
  }

  setCurrency(currency: any): void {
    this.currency = currency;
    this.set('currency', JSON.stringify(this.currency));
  }

  getList(): any {
    return JSON.parse(localStorage.getItem('list'));
  }

  getConfig(): any {
    // get config from local storage
    return JSON.parse(localStorage.getItem('config'));
  }

  get(key: string): any {
    return localStorage.getItem(key);
  }

  set(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }

  getAll(): any {
    return localStorage;
  }
}
