import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ICoin } from 'src/app/interfaces/icoin';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-coin-detail',
  templateUrl: './coin-detail.component.html',
  styleUrls: ['./coin-detail.component.scss']
})
export class CoinDetailComponent implements OnInit {
  coin: ICoin;
  quantity: number;
  entry: number;
  comment: string
  isEditing = false;
  
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private store: StoreService,
    private modalController : ModalController,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.coin = this.dialogConfig.data.coin;
    this.isEditing = this.dialogConfig.data.edit;
    this.quantity = this.coin.quantity? this.coin.quantity : null;
    this.comment = this.coin.comment? this.coin.comment : null;
    this.entry = this.coin.entry? this.coin.entry : null;
  }

  addCoinEvent(): void {
    this.store.addCoin({...this.coin, quantity: this.quantity, comment: this.comment, entry: this.entry});
    this.closeModal();
  }

  closeModal(): void {
    this.ref.close();
    setTimeout(() => {
      this.modalController.dismiss();
    }, 200);
  }
}
